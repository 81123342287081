<template>
  <div
    v-if="currentProgram.id"
    class="programDetailSettings"
  >
    <base-heading
      type="2"
      modifiers="centerDesktop"
      class="programDetailSettings__title"
    >
      {{ $t('programSettingsModal.title') }}
    </base-heading>

    <base-heading
      type="4"
      class="programDetailSettings__subTitle"
    >
      {{ $t('programSettingsModal.subTitle') }}
    </base-heading>

    <p class="programDetailSettings__body">
      {{ $t('programSettingsModal.body') }}
    </p>

    <p class="programDetailSettings__body">
      {{ $t('programSettingsModal.note') }}
    </p>

    <div>
      <base-button
        v-if="!restartSure"
        class="programDetailSettings__button"
        modifiers="secondary"
        v-on:click="restartSure = true"
      >
        {{ $t('programSettingsModal.restartButtonLabel') }}
      </base-button>

      <base-button
        v-else
        modifiers="secondary"
        v-bind:show-spinner="restartLoading"
        v-bind:loading-text="$t('programSettingsModal.restartButtonConfirmedLabel')"
        class="programDetailSettings__button"
        v-on:click="apiProgramRestart"
      >
        {{ $t('programSettingsModal.restartButtonConfirmLabel') }}
      </base-button>
    </div>

    <div>
      <base-button
        v-if="!stopSure"
        class="programDetailSettings__button"
        modifiers="secondary"
        v-on:click="stopSure = true"
      >
        {{ $t('programSettingsModal.stopButtonLabel') }}
      </base-button>

      <base-button
        v-else
        modifiers="secondary"
        v-bind:show-spinner="stopLoading"
        v-bind:loading-text="$t('programSettingsModal.stopButtonConfirmedLabel')"
        class="programDetailSettings__button"
        v-on:click="apiProgramStop"
      >
        {{ $t('programSettingsModal.stopButtonConfirmLabel') }}
      </base-button>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import BaseHeading from '@/components/BaseHeading';
import BaseButton from '@/components/BaseButton';

export default {
  components: {
    BaseHeading,
    BaseButton,
  },

  data() {
    return {
      restartLoading: false,
      stopLoading: false,
      restartSure: false,
      stopSure: false,
    };
  },

  computed: {
    ...mapGetters('program', ['currentProgram'])
  },

  methods: {
    ...mapActions({
      restart: 'program/restart',
      stop: 'program/stop',
    }),

    async apiProgramRestart() {
      this.restartLoading = true;

      await this.restart(this.currentProgram.id);

      this.$router.push({
        name: 'program',
        params: {
          programId: this.currentProgram.id,
          periodId: this.currentProgram.active_period,
        },
      }).catch(() => {});

      this.restartLoading = false;
      this.$emit('close');
    },

    async apiProgramStop() {
      this.stopLoading = true;

      await this.stop(this.currentProgram.id);

      this.$router.push({ name: 'programs' });
      this.stopLoading = false;
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/lib";

.programDetailSettings__title {
  @include desktop {
    margin: 0 0 rem(40px) 0;
  }
}

.programDetailSettings__body {
  @include paragraph;
  margin: 0 0 rem(20px) 0;
}

.programDetailSettings__button {
  margin: 0 0 1rem 0;
}
</style>
